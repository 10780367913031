@import url(https://fonts.googleapis.com/css?family=Roboto:300);
body,html{height:100%;background:#2fba91;min-width:320px}body{margin:0;font-family:'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}*{box-sizing:border-box}

.alert-block{z-index:100;position:fixed;width:100%;display:flex;justify-content:center}.alert-block .alert{min-width:200px;max-width:300px;padding:10px 20px;color:white;background:#114b5f;margin-top:-1000px;transition:ease-in 0.2s}.alert-block .alert.show{transition:all 0.3s;box-shadow:0 0 20px 0 rgba(0,0,0,0.2),0 5px 5px 0 rgba(0,0,0,0.24);margin-top:0;overflow:hidden;text-overflow:ellipsis}

.check-answer>div{margin-bottom:10px;margin-top:10px}.check-answer>div:first-child{margin-top:0}.check-answer>div:last-child{margin-bottom:0}

.footer{max-width:800px;padding:0 20px;margin:0 auto 30px}.footer .content{color:white;font-size:14px;text-align:center}.footer .content p{margin:10px}.footer .content p:first-child{margin-top:0}.footer .content a{color:white;border-bottom:1px solid rgba(255,255,255,0.49);text-decoration:none}.footer .content a:hover,.footer .content a:active,.footer .content a:focus{border-bottom:none}

.form-button{margin:0 0 15px}.form-button:last-child{margin:0}.form-button button{background:#114b5f;color:#fff;box-shadow:0 2px 5px 0 rgba(0,0,0,0.26);font-family:'Roboto', sans-serif;text-transform:uppercase;outline:0;width:100%;border:0;padding:15px;font-size:14px;transition:all 0.3 ease;cursor:pointer}.form-button button:hover,.form-button button:active,.form-button button:focus{background:#092934}.form-button button.grey{background:#dedede;color:#114b5f;box-shadow:0 2px 5px 0 rgba(0,0,0,0.26)}.form-button button.grey:hover,.form-button button.grey:active,.form-button button.grey:focus{background:#d1d1d1}

.main-menu-block{font-family:'Roboto', sans-serif;position:relative;max-width:800px;margin:0 auto;display:flex;flex-wrap:wrap;justify-content:center;padding:0 20px}.main-menu-block .main-menu{margin:0 10px;padding:10px;background:#fff;box-shadow:0 0 20px 0 rgba(0,0,0,0.2),0 5px 5px 0 rgba(0,0,0,0.24);display:flex;flex-wrap:wrap;justify-content:space-between;overflow:hidden;min-width:300px;flex:1 1 200px}.main-menu-block .main-menu .word{flex:2 1 200px;display:flex;justify-content:center;flex-direction:column;font-weight:bold;font-size:25px;color:#2d2d2d;padding:10px;width:100%}.main-menu-block .main-menu .word span{text-overflow:ellipsis;overflow:hidden}@media only screen and (max-width: 479px){.main-menu-block .main-menu .word{text-align:center}}.main-menu-block .main-menu .rounds{flex:1 1 200px;text-align:right}.main-menu-block .main-menu .menu{flex:1 1 400px;display:flex;margin:10px -10px -10px -10px;justify-content:space-between}.main-menu-block .main-menu .menu .menu-item{padding:4px;flex:1 1 200px;text-align:center;border-top:1px solid #f5efe3;border-left:1px solid #f5efe3;cursor:pointer;color:#114b5f;text-decoration:none;display:flex}.main-menu-block .main-menu .menu .menu-item>*{margin:auto}.main-menu-block .main-menu .menu .menu-item:hover,.main-menu-block .main-menu .menu .menu-item:focus,.main-menu-block .main-menu .menu .menu-item:active{background:#f5efe3}.main-menu-block .main-menu .menu .menu-item:first-child{border-left:none}

.modal-back{position:fixed;top:0;left:0;width:100%;height:100%;background:#0000009e;padding-left:10px;padding-right:10px;overflow-x:auto}.modal-back .modal{font-family:'Roboto', sans-serif;max-width:700px;background:white;margin:40px auto;position:relative}.modal-back .modal.width-auto{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.modal-back .modal .modal-title{padding:10px;font-weight:100}.modal-back .modal .modal-title h2{margin:0}.modal-back .modal .modal-body{padding:10px}.modal-back .modal .modal-body.no-padding{padding:0}.modal-back .modal.big-margin .modal-title{padding:20px}.modal-back .modal.big-margin .modal-body{padding:10px 20px}.modal-back .modal .modal-actions{padding:10px;display:flex;flex-wrap:wrap;justify-content:space-between;margin-left:-5px;margin-right:-5px;margin-top:-10px}.modal-back .modal .modal-actions .form-button{flex:1 1 200px;min-width:200px;margin:10px 5px 0}.modal-back .modal .close{width:20px;height:20px;display:none;cursor:pointer;position:absolute;top:25px;right:25px;opacity:0.8}.modal-back .modal.show-close-button .close{display:block}.modal-back .modal.show-close-button .modal-title h2{margin-right:30px}

.qr-code{background:white;min-width:300px;min-height:300px;padding:0}.qr-code .copied{position:absolute;width:300px;height:300px;background:#ffffffd9;text-align:center;display:flex;justify-content:center;flex-direction:column;font-size:25px;text-shadow:1px 1px 2px rgba(0,0,0,0.2)}.qr-code .url{text-align:center;max-width:300px;padding:0 10px;word-break:break-word;font-weight:bold;color:#114b5f}

.form{margin:10px;box-shadow:0 0 20px 0 rgba(0,0,0,0.2),0 5px 5px 0 rgba(0,0,0,0.24);width:800px;min-width:300px;background:#fff;padding:20px}.form .actions{display:flex;flex-wrap:wrap;justify-content:space-between;margin-left:-10px;margin-right:-10px}.form .actions .form-button{flex:1 1 200px;min-width:200px;margin-left:10px;margin-right:10px}.form.small{max-width:400px}.form p{font-family:'Roboto', sans-serif;font-size:12px}.form p:last-child{margin-bottom:0}.form p.right{text-align:right}.form h2{font-family:'Roboto', sans-serif;font-weight:100;margin-top:0}.form h2:last-child{margin-bottom:0}.form h1{font-family:'Roboto', sans-serif}.form h1:first-child{margin-top:0}

.qr-code-reader{background:#114b5f}.qr-code-reader section section div{border:none !important;box-shadow:none !important;background:url(/static/media/qrcode.851869bd.svg) no-repeat}

.answering .list-item.association{position:relative;padding-top:17px;padding-bottom:10px}.answering .list-item.association .player{text-transform:uppercase;font-size:8px;font-weight:bold;position:absolute;top:7px;text-shadow:none}

@-webkit-keyframes rotation{0%{transform:rotate(0deg)}100%{transform:rotate(-180deg)}}@keyframes rotation{0%{transform:rotate(0deg)}100%{transform:rotate(-180deg)}}.form-input{position:relative;margin:0 0 15px}.form-input:last-child{margin:0}.form-input.filled label{position:absolute;transition:all 0.1s;transform:scale(0.5) translate(0, -30px);font-weight:bold;color:#505050}.form-input .sync{-webkit-tap-highlight-color:transparent;position:absolute;width:25px;height:25px;right:13px;top:13px;cursor:pointer}.form-input .sync.rotation{-webkit-animation-name:rotation;animation-name:rotation;-webkit-animation-duration:0.3s;animation-duration:0.3s;-webkit-animation-iteration-count:1;animation-iteration-count:1;-webkit-animation-timing-function:cubic-bezier(0.52, 0.5, 0.25, 1);animation-timing-function:cubic-bezier(0.52, 0.5, 0.25, 1)}.form-input label{transform-origin:left;transition:all 0.2s;font-family:'Roboto', sans-serif;position:absolute;font-size:16px;top:16px;left:16px;color:#909090;cursor:auto}.form-input .validMessage{color:#bd1d1d}.form-input.sync input[type='text']{padding-right:45px}.form-input input[type='text']{font-family:'Roboto', sans-serif;outline:0;background:#f2f2f2;width:100%;border:1px solid #f2f2f2;padding:15px 15px 13px;box-sizing:border-box;font-size:16px;border-radius:0;-webkit-appearance:none}.form-input input[type='text']:hover,.form-input input[type='text']:active,.form-input input[type='text']:focus{border:1px solid #114b5f}.form-input.invalid input[type='text']{border:1px solid #bd1d1d}@media not all and (min-resolution: 0.001dpcm){@supports (-webkit-appearance: none){.form-input label{top:18px}}}

.page{max-width:800px;margin:0 auto;display:flex;flex-wrap:wrap;justify-content:center;padding:20px}

.list{font-family:'Roboto', sans-serif;margin-bottom:20px;box-shadow:0 0 10px 0 rgba(0,0,0,0.2),0 4px 5px 0 rgba(0,0,0,0.24)}.list:last-child{margin-bottom:0}.list .list-title{background:#2fba91;color:#fcfaf6;text-shadow:1px 1px 2px rgba(0,0,0,0.2);font-size:15px;padding:15px 10px;text-transform:uppercase;font-weight:bold;overflow-wrap:break-word}.list .list-item{background:#2fba91;color:#fcfaf6;text-shadow:1px 1px 2px rgba(0,0,0,0.2);font-size:15px;padding:10px;border-top:1px dashed rgba(255,255,255,0.3);overflow-wrap:break-word;word-break:break-word}.list .list-item.selected{background:#259171}.list .list-item.selected:hover{background:#23896b}.list .list-item.selected:hover.readonly{background:#259171}.list .list-item:hover{background:#289e7b;transition:0.1s all ease;padding-left:20px}.list .list-item:first-child{border-top:none}.list.readonly .list-item{padding-left:10px;background:#2fba91}.list.readonly .list-item.selected{background:#259171}

.choose_word .list .list-item{cursor:pointer}

.associations .list .list-item{cursor:pointer}.associations .list .list-item div{position:relative;width:100%;vertical-align:middle;display:flex}.associations .list .list-item div span{display:flex;justify-content:center;flex-direction:column}.associations .list .list-item div span.association{position:relative;padding-top:7px;flex:1 1}.associations .list .list-item div span.association .player{text-transform:uppercase;font-size:8px;font-weight:bold;position:absolute;top:-3px;text-shadow:none}.associations .list .list-item div .deleted{font-size:8px;text-transform:uppercase;font-weight:bolder;margin-right:5px;vertical-align:middle;max-width:40px;min-width:40px;margin-left:10px;text-align:right}

.finish .statistic{margin-bottom:25px}.finish .word-block{margin-top:-5px}.finish .word-block .word{vertical-align:middle;display:flex}.finish .word-block .word .value{flex:1 1;font-size:20px;display:flex;flex-direction:column;justify-content:center;margin-bottom:5px}.finish .word-block .word .status{font-size:8px;text-transform:uppercase;font-weight:bolder;margin-right:5px;margin-left:10px;text-align:right;display:flex;flex-direction:column;justify-content:center}.finish .word-block .associations .association{display:inline-block;margin-right:5px;cursor:pointer}.finish .word-block .associations .association:last-child{margin-right:0}.finish .word-block .associations .association.invalid{color:#ffffff91;text-decoration:line-through}.finish .players-header{display:flex;justify-content:space-between}.finish .players-header .title{display:flex;justify-content:center;flex-direction:column}.finish .players-header .players-statistic-header{text-align:right;font-size:12px}.finish .player{display:flex;justify-content:space-between}.finish .player .login{font-size:20px;display:flex;justify-content:center;flex-direction:column}.finish .player .player-statistic{text-align:right;display:flex;justify-content:center;flex-direction:column;margin-left:10px}.finish .player .player-statistic .associations,.finish .player .player-statistic .master{white-space:nowrap;text-transform:uppercase;font-size:15px}

.waiting-players .list .list-item{text-transform:uppercase}.waiting-players .list .list-item.you{font-size:20px;padding-top:20px;padding-bottom:20px}

.rules{font-family:'Roboto', sans-serif}.rules .intro{margin-top:0}.rules h3{font-weight:bold;margin:0}

