.rules {
  font-family: 'Roboto', sans-serif;

  .intro {
    margin-top: 0;
  }

  h3 {
    font-weight: bold;
    margin: 0;
  }
}
