@import '../../app/theme';

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

.form-input {
  position: relative;
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }

  &.filled {
    label {
      position: absolute;
      transition: all 0.1s;

      transform: scale(0.5) translate(0, -30px);
      font-weight: bold;
      color: #505050;
    }
  }

  .sync {
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    width: 25px;
    height: 25px;
    right: 13px;
    top: 13px;
    cursor: pointer;

    &.rotation {
      animation-name: rotation;
      animation-duration: 0.3s;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0.52, 0.5, 0.25, 1);
    }
  }

  label {
    transform-origin: left;
    transition: all 0.2s;
    font-family: 'Roboto', sans-serif;
    position: absolute;
    font-size: 16px;
    top: 16px;
    left: 16px;
    color: #909090;
    cursor: auto;
  }

  .validMessage {
    color: #bd1d1d;
  }

  &.sync {
    input[type='text'] {
      padding-right: 45px;
    }
  }

  input[type='text'] {
    font-family: 'Roboto', sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 1px solid $input-border-color;
    padding: 15px 15px 13px;
    box-sizing: border-box;
    font-size: 16px;

    border-radius: 0;
    -webkit-appearance: none;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $input-border-color-hover;
    }
  }

  &.invalid {
    input[type='text'] {
      border: 1px solid #bd1d1d;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      label {
        top: 18px;
      }
    }
  }
}
